import { createTypes, createAction } from "lib/action";

const LOAD_ALARM_EVENT = createTypes("alarm_event", "load");

const LOAD_ADAS_EVENT_REPORT = createTypes("adas_event_report", "load");

const loadAlarmEvent = {
  request: (data) => createAction(LOAD_ALARM_EVENT.REQUEST, { data }),
  success: (data) => createAction(LOAD_ALARM_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_ALARM_EVENT.FAILURE),
};

const loadAdasEventReport = {
  request: (data) => createAction(LOAD_ADAS_EVENT_REPORT.REQUEST, { data }),
  success: (data) => createAction(LOAD_ADAS_EVENT_REPORT.SUCCESS, { data }),
  failure: () => createAction(LOAD_ADAS_EVENT_REPORT.FAILURE),
};

export {
  loadAlarmEvent,
  LOAD_ALARM_EVENT,
  loadAdasEventReport,
  LOAD_ADAS_EVENT_REPORT,
};
