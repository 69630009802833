import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
//import { push } from "connected-react-router";

import * as actions from "./actions";
import moment from "moment";

function* loadAdasEventReport(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const {
    data: { list_vehicle_id, date_start, date_end, type_file, event_type },
  } = action.payload;
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/adasevent`,
      {
        date_start,
        date_end,
        list_vehicle_id,
        company_id: `${COMPANY_ID}`,
        type_file,
        event_type,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadAdasEventReport.success(res.data));
    window.location = res.data.result;
  } catch (err) {
    console.log("error", err);
    yield put(actions.loadAdasEventReport.failure());
  }
}

function* loadAlarmEvent(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/adas-report/getevent`,
      {
        headers: { Authorization: `Bearer ${ACCESSTOKEN}` },
      }
    );
    yield put(actions.loadAlarmEvent.success(res.data));
  } catch (err) {
    yield put(actions.loadAlarmEvent.failure());
  }
}
export default function* watchAdasevent() {
  yield all([
    takeEvery(actions.LOAD_ALARM_EVENT.REQUEST, loadAlarmEvent),
    takeEvery(actions.LOAD_ADAS_EVENT_REPORT.REQUEST, loadAdasEventReport),
  ]);
}

export { loadAlarmEvent, loadAdasEventReport };
