import React, { Component } from "react";
import { Select, Divider, Icon } from "antd";
export default class DisplaySelector extends Component {
  generateSlectOptionFromDisplay = () => {
    return this.props.displayList.map((v) => {
      return (
        <Select.Option
          key={v.display_id}
          value={v.display_id}
          style={{ background: "#fff !impotant" }}
        >
          {v.display_name}
        </Select.Option>
      );
    });
  };
  generateSlectOptionFromVehicleStatus = () => {
    return this.props.displayList.map((v) => {
      return (
        <Select.Option
          key={v.display_id}
          value={v.display_id}
          style={{ background: "#fff !impotant" }}
        >
          {v.display_name}
        </Select.Option>
      );
    });
  };
  genrateDropdownRender = (menu) => (
    <div>
      {menu}
      <Divider style={{ margin: "4px 0" }} />
      <div
        style={{ textAlign: "center", cursor: "pointer", margin: "10px 0" }}
        onMouseDown={(e) => e.preventDefault()}
        onClick={this.props.showModalManageTemplate}
      >
        จัดการแบบ
      </div>
    </div>
  );
  handleSelectDisplayChange = (v) => {
    if (v != -99) this.props.handleDisplaySelect(v, true);
  };
  render() {
    return (
      <Select
        loading={this.props.formsLoading}
        showSearch
        value={this.props.displaySelect}
        style={{ width: "70%", marginRight: "5px" }}
        onChange={this.handleSelectDisplayChange}
        size="small"
        dropdownRender={this.genrateDropdownRender}
      >
        <Select.Option key={-99} value={-99}>
          กำหนดเอง
        </Select.Option>
        {this.generateSlectOptionFromDisplay()}
      </Select>
    );
  }
}
